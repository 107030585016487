<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.onlineBets') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2" v-show="!isRedirect">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2" v-show="!isRedirect">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="statusModel" :options="ticketStatuses" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.status') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="typeModel" :options="ticketTypes" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.ticketType') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-text-left p-grid" v-show="!isRedirect">
                        <p class="p-mt-2 p-ml-2">{{ $t('bets.dateFilterFooter') }}</p>
                        <div class="p-col-12 p-md-2">
                            <div class="p-field-radiobutton">
                                <RadioButton id="option3" name="option" :value="toggleOptions[0].value" v-model="useCreateDateToggle" />
                                <label for="option3">{{ toggleOptions[0].label }}</label>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <div class="p-field-radiobutton">
                                <RadioButton id="option3" name="option" :value="toggleOptions[1].value" v-model="useCreateDateToggle" />
                                <label for="option3">{{ toggleOptions[1].label }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="isRedirect" class="p-col-8 p-text-right"><Button :label="$t('buttons.back')" class="p-button-raised p-button-info" @click="goto(backRoute)" /></div>
                    <div v-else class="p-col-6 p-text-right"><Button :label="$t('buttons.getData')" class="p-button-raised p-button-success" @click="applyFiltersOnly()" /></div>
                </div>
                <Fieldset v-show="!isRedirect" :legend="$t('search.byId')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <InputText style="width: 100%" type="text" v-model="ticketSerial" />
                                <label>{{ $t('bets.ticketSerial') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-text-right"><Button :label="$t('buttons.searchData')" class="p-button-raised p-button-warning" @click="applySerialOnly()" /></div>
                    </div>
                </Fieldset>
                <Fieldset v-show="!isRedirect" :legend="$t('search.byFixtureId')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <InputText style="width: 100%" type="text" v-model="fixtureId" />
                                <label>{{ $t('sportsbets.fixtureId') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-text-right"><Button :label="$t('buttons.searchData')" class="p-button-raised p-button-warning" @click="applyFixtureOnly()" /></div>
                    </div>
                </Fieldset>
                <DataTable
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    v-model:expandedRows="expandedRows"
                >
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column :header="$t('general.status')">
                        <template #body="{ data }">
                            <Button :label="$t('bets.status.' + getTicketStatusName(data.Status))" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + getTicketStatusColor(data.Status) + ' p-mr-2'" />
                        </template>
                    </Column>
                    <Column field="TicketSerial" :header="$t('bets.ticketSerial')" :sortable="true"></Column>
                    <Column field="TicketDate" :header="$t('bets.placeDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.TicketDate) }} </template>
                    </Column>
                    <Column field="OnlineUserId" :header="$t('bets.user')"></Column>
                    <Column field="PlayedAmount" :header="$t('bets.playedAmount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.PlayedAmount) }} </template>
                    </Column>
                    <Column field="StakeAmount" :header="$t('bets.stakeAmount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.StakeAmount) }} </template>
                    </Column>
                    <Column field="TotalOdd" :header="$t('bets.totalOdd')" :sortable="true"></Column>
                    <Column field="NoOfEvents" :header="$t('bets.noOfEvents')" :sortable="true"></Column>
                    <Column field="MaxWinAmount" :header="$t('bets.maxWinAmount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.MaxWinAmount) }} </template>
                    </Column>
                    <Column>
                        <template #body="{ data }">
                            <Button :title="$t('bets.cashoutTitle')" v-show="data.EarlyCashoutActive" icon="pi pi-dollar" class="p-button-rounded p-button-success p-button-text p-mr-2" />
                        </template>
                    </Column>
                    <Column field="WinInfo" :header="$t('bets.winAmount')" :sortable="true">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.WinInfo) ? formatCurrency(data.WinInfo.WinAmount) : 0 }} </template>
                    </Column>
                    <Column field="PayInfo" :header="$t('bets.payDate')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.PayInfo) ? formatCSDateAndTime(data.PayInfo.Date) : '' }} </template>
                    </Column>
                    <Column field="VoidInfo" :header="$t('bets.voidDate')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.VoidInfo) ? formatCSDateAndTime(data.VoidInfo.Date) : '' }} </template>
                    </Column>
                    <Column>
                        <template #body="{ data }">
                            <Button v-show="data.DeviceType == 'Mobile'" icon="pi pi-mobile" class="p-button-rounded p-button-info p-button-text p-mr-2" />
                            <Button v-show="data.DeviceType == 'Desktop'" icon="pi pi-desktop" class="p-button-rounded p-button-info p-button-text p-mr-2" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <SportTicketDetails :ticketSerial="slotProps.data.TicketSerial"></SportTicketDetails>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoTicketsApi } from '../../service/BoTicketsApi';
import { BoApi } from '../../service/BoApi';
import { SportsApi } from '../../service/SportsApi';

import SportTicketDetails from '../Bets/Components/SportTicketDetails.vue';

import settings from '../../settings/generalSettings';
import { uiSettings } from '../../settings/uiSettings';
export default {
    name: 'sportsTickets',
    components: {
        SportTicketDetails,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            cashiersDictionary: [],
            shopsDictionary: [],
            startDateModel: null,
            endDateModel: null,
            BoTicketsApi: new BoTicketsApi(),
            BoApi: new BoApi(),
            SportsApi: new SportsApi(),
            ticketStatuses: settings.TicketStatuses,
            statusModel: { name: 'All', value: 100 },
            items: [],
            ticketType: settings.ticketType.sportBets,
            sort: 'ticketDate|desc',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            ticketSerial: '',
            useCreateDateToggle: 1,
            fixtureId: '',
            ticketTypes: [
                { name: 'All', value: 'online' },
                { name: 'Mobile', value: 'mobile' },
                { name: 'Desktop', value: 'desktop' },
            ],
            typeModel: { name: 'All', value: 'online' },
            expandedRows: [],
        };
    },
    props: {
        backRoute: {
            type: String,
        },
        ticketSerials: {
            type: String,
            default: '',
        },
    },
    computed: {
        toggleOptions() {
            return [
                {
                    value: 1,
                    label: this.$t('bets.placeDate'),
                },
                {
                    value: 0,
                    label: this.$t('bets.processDate'),
                },
            ];
        },
        isRedirect() {
            return this.notNullOrEmptyField(this.ticketSerials);
        },
    },
    watch: {
        statusModel() {
            if (!this.notNullOrEmptyObject(this.statusModel)) {
                this.statusModel = { name: 'All', value: 100 };
            }
        },
        typeModel() {
            if (!this.notNullOrEmptyObject(this.typeModel)) {
                this.typeModel = { name: 'All', value: 'online' };
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59:00';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.readItems();
    },
    methods: {
        applyFiltersOnly() {
            this.ticketSerial = '';
            this.fixtureId = '';
            this.readItems();
        },
        applySerialOnly() {
            this.statusModel = { name: 'All', value: '100' };
            this.typeModel = { name: 'All', value: 'online' };
            this.fixtureId = '';
            this.readItems();
        },
        applyFixtureOnly() {
            this.statusModel = { name: 'All', value: '100' };
            this.typeModel = { name: 'All', value: 'online' };
            this.ticketSerial = '';
            this.readItems();
        },
        onPage(event) {
            this.readItems(event.page + 1);
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
        },
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;

            if (this.isRedirect) {
                const params = {
                    per_page: this.perPage,
                    page: page,
                    sort: this.sort,
                    status: this.statusModel.value,
                    ticketSerial: this.ticketSerials,
                };
                this.SportsApi.getTicketsBySerials(params)
                    .then((response) => {
                        if (this.notNullOrEmptyObject(response.data)) {
                            this.items = response.data.data;
                            this.totalPages = response.data.total;
                        }

                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.checkForUnauthorizedError(error);
                    });
            } else {
                const params = {
                    per_page: this.perPage,
                    page: page,
                    sort: this.sort,
                    startDate: this.formatApiDate(this.startDateModel),
                    endDate: this.formatApiDate(this.endDateModel),
                    shop: 'online',
                    status: this.statusModel.value,
                    ticketSerial: this.ticketSerial,
                    ticketType: this.ticketType,
                    lotteryCode: 0,
                    fixtureId: this.fixtureId,
                    typeFilter: this.typeModel.value,
                    useCreateDate: this.useCreateDateToggle == 1 ? true : false,
                    payShop: 'online',
                };
                this.BoTicketsApi.getTickets(params)
                    .then((response) => {
                        if (this.notNullOrEmptyObject(response.data)) {
                            this.items = response.data.data;
                            this.totalPages = response.data.total;
                        }

                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.checkForUnauthorizedError(error);
                    });
            }
        },
    },
};
</script>